<template>
    <div class="mnlogin">
      <div class="content-box">
         <div class="login-box">
            <div class="col">
                <div>准考证号:</div>
                <div style="margin-left:5px">
                    <el-input v-model="input" placeholder="11111111"></el-input>
                </div>
             </div>
             <div class="col">
                <div>证件号码:</div>
                <div style="margin-left:5px">
                    <el-input v-model="input" placeholder="11111111"></el-input>
                </div>
             </div>
             <div class="login-btn">
                <div class="btn-box" style="margin-right:5px;" @click="goToDetail">
                    登录
                </div>
                <div class="btn-box" style="margin-left:5px;">
                    重置
                </div>
             </div>
         </div>
      </div>
      <div style="margin-top:20px;width:526px;text-align:left;">
        说明：请使用准考证号：11111111111，证件号码：111111111111111111
      </div>
      <el-dialog
        title="重要提示"
        :visible.sync="dialogVisible"
        :show-close="false"
        :close-on-click-modal="false"
        class="tishi"
        :center="false"
        width="950px">
        <div class="line"></div>
        <div class="content_box">
            <div class="sbox"></div>
            <div class="text_box">
                <div class="box">
                    <div class="colBox">
                        <b>会计人员职业道德规范</b>
                    </div>
                    
                    <div class="colBox" style="font-size: 16pt;margin-top:20px;">
                        <b>坚持诚信，守法奉公。坚持准则，守责敬业。坚持学习，守正创新。</b>
                    </div>
                    <div class="colBox" style="margin-top:20px;">
                        <b>会计人员职业道德规范</b>
                    </div>
                    <div class="spanBox">
                        <span>第二百八十四条　非法使用窃听、窃照专用器材，造成严重后果的，处二年以下有期徒刑、拘役或者管制。</span><br>
                        <span>第二百八十四条之一　在法律规定的国家考试中，组织作弊的，处三年以下有期徒刑或者拘役，并处或者单处罚金；情节严重的，处三年以上七年以下有期徒刑，并处罚金。</span><br>
                        <span>为他人实施前款犯罪提供作弊器材或者其他帮助的，依照前款的规定处罚。</span><br>
                        <span>为实施考试作弊行为，向他人非法出售或者提供第一款规定的考试的试题、答案的，依照第一款的规定处罚。</span><br>
                        <span>代替他人或者让他人代替自己参加第一款规定的考试的，处拘役或者管制，并处或者单处罚金。</span><br>
                    </div>
                    <div class="colBox" style="margin-top:28px;">
                        <b>考生不得以任何方式传播考试试题</b>
                    </div>
                </div>

            </div>
  
        </div>
        <div class="footer_box">
            <div class="footer_btn" @click="goNext()">我已阅知上述条款</div>
        </div>
       
    </el-dialog>
     
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'mnlogin',
    components: {},
    
    watch: {
      
    },
    data() {
      return {
        dialogVisible:false,
        input:'111111111'
      }
    },
    mounted(){
     
    },
    methods: {
        goNext(){
            this.dialogVisible = false;
            this.$router.push({
                path:'/mndetail',
                query: {
                    tabIndex: this.$route.query.tabIndex,
                    testPaperId: this.$route.query.testPaperId,
                    type:this.$route.query.type
                }
            })

        },
        goToDetail(){
           this.dialogVisible = true;
            
        }
      
    }
  }
  </script>
  
  <style lang="less" scoped>
  .tishi{
      /deep/ .el-dialog__header{
          text-align: left;
          font-size: 24px;
            font-weight: bold;
            background: #d9ebff;
      }
      /deep/ .el-dialog__body{
          padding : 0px;
      }
      .line{
          width:100%;
          height: 1px;
          background-color: 100%;
      }
      .content_box{
          width:100%;
          height: 455px;
          background-color: #d9ebff;
          .sbox{
              width: calc(100% - 70px);
              margin-left: 35px;
              height: 1px;
              background-color: #d9ebff;
          }
          .text_box{
              width: calc(100% - 70px);
              margin-left: 35px;
              height: 420px;
              background-color: #ffffff;
              .box{
                line-height: 1.5em;
                padding: 20px;
                font-family: '\5FAE\8F6F\96C5\9ED1','\5B8B\4F53';
                margin-left: 30px;
                margin-right: 30px;
                background: white;
                margin-top: 17px;
                  .colBox{
                      text-align: center;
                      font-size: 18pt;
                      color: black;
                  }
                  .spanBox{
                      margin-top: 20px;
                      text-align: left;
                      line-height: 2em;
                    font-family: '\5FAE\8F6F\96C5\9ED1','\5B8B\4F53';
                    font-size: 16px;
                    color: black;
                    font-weight: 400;
                  }
                 
              }
          }
      }
      .footer_box{
          width:100%;
          height:72px;
          display: flex;
          align-items: center;
          justify-content: center;
          .footer_btn{
              width:150px;
              height: 42px;
              text-align: center;
              line-height: 42px;
              font-weight: bold;
              background-color: #fca32f;
              border: 1px solid #dedede;
                font-family: "\5FAE\8F6F\96C5\9ED1","\5B8B\4F53";
                color: #fff;
                border-radius: 2px;
                cursor: pointer;
          }
      }
  }
  .mnlogin {
    width:100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/tiku/mnjkbackground.jpg");
    background-repeat: repeat-x;
    background-color: #f5f8fd;
    .content-box{
        width: 526px;
        height: 291px;
        border-radius: 20px;
        background-image: url("../../assets/tiku/wzh.png");  
        position: relative;
        .login-box{
            width: 298px;
            height: 160px;
            position: absolute;
            right: 25px;
            bottom: 35px;
            .login-btn{
                width: 100%;
                height: 40px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 20px;
                div{
                    background-image: url("../../assets/tiku/btnback.jpg");
                }
                .btn-box{
                    width: 75px;
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    color: #f5f8fd;
                    font-size: 16px;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
            .col{
                display: flex;
                flex-direction: row;
                height: 40px;
                width: 100%;
                align-items: center;
                /deep/ .el-input__inner{
                    height: 25px;
                    line-height: 25px;
                }
            }

        }
    }
    
  }
  </style>